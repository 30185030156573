#Home_HomeTop {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
    gap: 2%;

}
#Home_HomeTop > a {
    position: relative;
    width: 100%;
}
#Home_HomeTop img {
    width: 100%;
}
.Home_HomeTop_Title {
    position: absolute;
    bottom: 5%;
    color: white;
    width: 100%;
    text-align: center;
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
}
@media only screen and (max-width: 700px) {
    .Home_HomeTop_Title_en {
        font-size: 12px;
    }
}