#footerWrapper {
    padding: 25px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#footerTopLine {
    position: relative;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
    border-top: 1px solid #ececec;
}
#footerLogo a {
    color: black;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    white-space: nowrap;
}
#footerMiddleWrapper {
    font-size: 12px;
    color: #C4C4C4;
}
#footerMiddleTop {
    display: flex;
    flex-direction: row;
    gap: 15px;
}
#footerMiddleTop > a {
    display: flex;
    text-decoration: none;
    color: #C4C4C4;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.languageSettingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}
.languageSettingContainer > select {
    font-size: 16px;
    color: #A7A3A3;
}

@media only screen and (max-width: 700px) {
    #footerWrapper {
        flex-direction: column;
        gap: 15px;
    }
    #footerMiddleTop {
        flex-direction: column;
        gap: 5px;
    }
    #footerMiddleTop > div {
        justify-content: flex-start;
    }
}