#Contact_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 15px;
}
#Contact_ContactLeft {
    width: 100%;
}
#Contact_ContactRight {
    width: 100%;
}
#Contact_ContactRight_img {
    width: 100%;
}

#contact_us_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#contact_us_left_container {
    width: 90%;
    margin: auto;
}

#contact_us_left_title {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 30px 0;
}

#Contact_ContactLeft > input,
#Contact_ContactLeft > textarea,
#Contact_ContactLeft > select {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #a7a3a3;
    border-radius: 5px;
    margin-bottom: 10px;
}

#Contact_ContactLeft > input,
#Contact_ContactLeft > select {
    height: 30px;
}

.contact_us_left_label {
    margin: 10px 0;
}

#contact_us_left_err {
    color: #ff4848;
}

#contact_us_left_submit {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    margin: 20px auto;
    color: white;
    background-color: #8e75bd;
    padding: 5px 25px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}

#contact_us_left_sending {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    margin: 20px auto;
    color: white;
    background-color: #a9a9a9;
    padding: 5px 25px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}
#contact_us_right_container {
    padding: 20px;
    background-color: #341e5e;
    color: #f7f4fc;
    width: 100%;
}

#contact_us_right_love_to {
    font-size: 40px;
    font-weight: 600;
    padding: 20px 0;
}

#contact_us_right_contact {
    font-size: 25px;
    font-weight: 600;
}

#contact_us_right_address {
    font-size: 25px;
    font-weight: 600;
}

#contact_us_right_icon {
    position: absolute;
    right: 0;
}

@media only screen and (max-width: 700px) {
    #Contact_wrapper {
        flex-direction: column;
    }
    #contact_us_container {
        grid-template-columns: 1fr;
        overflow: hidden;
    }

    #contact_us_right_icon {
        position: relative;
    }
}
