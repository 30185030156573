#brandTop {
    display: flex;
    justify-content: center;
    position: relative;
    height: calc(90vw / 2);
}

.brandTop_img {
    display: flex;
    object-fit: cover;
    margin: auto;
    transition: opacity .6s;
    position: absolute;
}