#Home_HomeVideo {
    
    margin-bottom: 20px;
}
#Home_HomeVideoContent {
    /*width: 100%;*/
}

#Home_HomeVideo img {
    width: 100%;
    height: calc(100vh - 200px);
}

@media only screen and (max-width: 700px) {
    #Home_HomeVideoContent {
        /*height: auto;*/
    }
}