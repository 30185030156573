#brandMid_ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
    margin: auto;
    width: 90%;
}

#brandMid_ul > li {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 10px 0;
    margin: 15px;
    background-color: white;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
}

@media only screen and (max-width: 700px) {
    #brandMid_ul {
        grid-template-columns: 1fr 1fr;
    }

}