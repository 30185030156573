#Home_HomeMid {
    margin: 30px auto;
    text-align:center;
}

#Home_HomeMid_Cover {
    margin-top: 60px;
    margin-bottom: 30px;
    font-family: Noto Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
}
#Home_HomeMid_Cover_detail {
    margin-bottom: 30px;
    font-family: Noto Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}
#Home_HomeMid_details {
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2%;
}
.Home_HomeMid_images {
    width: 100%;
}
.Home_HomeMid_details_textContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.Home_HomeMid_details_textContainer > h4 {
    margin-top: 20px;
    line-height: 18px;
    font-family: Noto Serif;
    font-size: 20px;
    color: #414040;
    font-weight: bold;
}
.Home_HomeMid_details_textContainer > div > h4 {
    margin-top: 20px;
    margin-bottom: 0px;
    line-height: 18px;
    font-family: Roboto;
    font-size: 14px;
    color: #2C5486;
    font-weight: bold;
    text-align:left;

}

.Home_HomeMid_details_textContainer > div > p {
    margin-top: 5px;
    line-height: 18px;
    font-family: Roboto;
    font-size: 14px;
    color: black;
    text-align: left;
}
#Home_HomeMid_details_right {
    display: flex;
    flex-direction: column;
}
.Home_HomeMid_images_left {
    height: 550px;
    object-fit: cover;
}
.Home_HomeMid_images_right {
    height: 200px;
    object-fit: cover;
}

.Home_HomeMid_details_textContainer_right {
    height: 150px;
}

@media only screen and (max-width: 700px) {
    #Home_HomeMid_details {
        width: 100%;
    }
    #Home_HomeMid_details h4 {
        margin-top: 5px;
    }
}