#locationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
}

#locationCardContainer {
    color: white;
    background-color: #424242;
    border-radius: 10px;
    margin: 20px 0px;
    padding: 20px;
    text-align: left;
}

.locationCardDescriptions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.locationCardDescriptions div {
    margin-top: 5px;
    margin-left: 10px;
}

#map {
    width: 80%;
    height: 100%;
    z-index: 0;
}

#mapDescription {
    padding: 5px;
    background-color: #424242;
    color: white;
    width: 150px;
    border-radius: 10px;
}


#mapDescription a {
    color: white;
}



#Contact_ContactMap_list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}

#Contact_ContactMap_list > li {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}

#Contact_ContactMap_list > li > a {
    margin: 0 10px;
    text-decoration: none;
    margin: 0 10px;
    color: black;
}

.Contact_ContactMap_data {
    font-family: Roboto;
    font-size: 16px;
    color: #2C5486;
    font-weight: bold;
    padding-top: 5px;
}



@media only screen and (max-width: 700px) {
    #Contact_ContactMap_list {
        width: 100%;
    }
}