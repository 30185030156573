#Home_HomeContactWrapper {
    width: 70%;
    margin:auto;
    margin-bottom: 35px;

}
#Home_HomeContact_title {
    font-family: Roboto;
    font-size: 28px;
    color: #2C5486;
    font-weight: bold;
    padding: 15px 0;
}

#Home_HomeContact_list {
    list-style: none;
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    padding: 0;
}

#Home_HomeContact_list > li {
    display: flex;
    flex-direction: row;
}

#Home_HomeContact_list > li > a {
    margin: 0 10px;
    text-decoration: none;
    color: black;
}

.Home_HomeContact_data {
    font-family: Roboto;
    font-size: 16px;
    color: #2C5486;
    font-weight: bold;
    padding-top: 5px;
}


@media only screen and (max-width: 1000px) {
    #Home_HomeContact_list {
        flex-direction: column;
    }

    #Home_HomeContact_list > li {
        margin: 15px 0;
    }
}

@media only screen and (max-width: 700px) {
    #Home_HomeContact_list {
        width: 100%;
    }

    
}