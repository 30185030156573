#About_AboutTop {
    margin: 45px auto 70px auto;
    text-align: center;
    max-width: 570px;
}

#About_AboutTop_leader {
    color: #2C5486;
    font-size: 32px;
    font-family: Noto Serif;
    font-weight: 700;
    margin-bottom: 12px;
}

#About_AboutTop_goal {
    font-size: 20px;
}

@media only screen and (max-width: 700px) {
}