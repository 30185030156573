#About_AboutBot {
    margin-top: 17px;
    font-family: roboto;
}

#About_AboutBot_Row1, #About_AboutBot_Row3 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.About_AboutBot_img {
    width: 100%;
}
#About_AboutBot_Row1_left {
    font-size: 16px;
}

#About_AboutBot_Row1_left_line {
    margin-bottom: 30px;
}
#About_AboutBot_Row1_left_year {
    color: #2E3953;
    font-size: 32px;
    font-weight: 700;
    font-family: Noto Serif;
}
#About_AboutBot_Row1_right {
    width: 100%;
}

#About_AboutBot_Row2 {
    padding: 10% 0;
    top: -50px;
    position: relative;
    background-color: #E8ECEF;
    z-index: -1;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
}

#About_AboutBot_Row2_content {
    width: 95%;
    max-width: 1100px;
    margin: auto;
}
#About_AboutBot_Row2_content_perfect {
    font-size: 32px;
    font-weight: 700;
    font-family: Noto Serif;
    color: #2E3953;
    margin: 12px 0;

}
#About_AboutBot_Row2_content_description {
    width: 80%;
}

#About_AboutBot_Row3_left {
    width: 100%;
}
#About_AboutBot_Row3_right {
    margin-top: 100px;
    width: 70%;
}
#About_AboutBot_Row3 {
    position: relative;
    top: -100px;
    gap: 52px;
}
.About_AboutBot_Row3_right_title {
    font-size: 32px;
    font-weight: 700;
    font-family: Noto Serif;
    color: #2E3953;
}
#About_AboutBot_Row3_right_description {
    margin-top: 20px;
}
#About_AboutBot_Row3_left_quality {
    font-size: 32px;
    font-weight: 700;
    font-family: Noto Serif;
    color: #2E3953;
    margin: 5px 0;
}

@media only screen and (max-width: 900px) {
    #About_AboutBot_Row1_left {
        margin-bottom: 25px;
    }
    #About_AboutBot_Row2 {
        top: 0px;
    }

    #About_AboutBot_Row3 {
        top: 0px;
    }

    #About_AboutBot_Row1, #About_AboutBot_Row3 {
        flex-direction: column;

    }
    #About_AboutBot_Row3 {
        gap: 0px;
    }
    #About_AboutBot_Row3_right {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 100%;
    }
}