#mobileMenuContainer {
    display: none;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    margin-left: 100%;
    background-color: transparent;
    transition: margin-left ease-in-out .3s;
    z-index: 999;
}
#mobileMenu {
    width: 300px;
    height: 100%;
    margin-left: auto;
    /*background: linear-gradient(200deg,#ffffff 0%, #b7d1ef 74%);*/
    background-color: white;
}
#mobileMenu_close {
    margin-right: 10px;
}
#mobileMenu_Top {
    display: flex;
    height: 60px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #2E3853;
    width: 100%;
    margin-bottom: 15px;
}
#mobileMenu_Top > div {
    display: flex;
    margin: auto;
}
#mobileMenu_Top_Logo {
    text-align: center;
    font-family: Roboto;
    font-weight: bold;
    font-size: 20px;
    color: white;
    text-decoration: none;
}
#mobileMenu_Mid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}
.mobileMenu_Link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F4F6F8;
    border-radius: 10px;
    width: 125px;
    height: 125px;
    transition: transform .7s;
    box-shadow: 3px 3px 7px 2px rgba(0, 0, 0, 0.05);
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: #2C5486;
    text-decoration: none;
}
.mobileMenu_Link:hover {
    transform: translateX(-5px) translateY(-5px); /*rotateX( 25deg );*/
    box-shadow: 3px 3px 7px 2px rgba(0, 0, 0, 0.2);
}
.mobileMenu_Link:nth-child(2n+1) {
    margin-left: auto;
}
/*#mobileMenu_Link_Adjustment {
    margin-top: 0;
    top: -11px;
    position: relative;
}*/
.mobileMenu_Link > div {
    margin-top: 15px;
}
@media only screen and (max-width: 700px) {
    #mobileMenuContainer {
        display: flex;
    }
}