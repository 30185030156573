#loadingWrapper {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: white;
    display: none;
    justify-content:center;
    align-items: center;
    flex-direction: column;
}

#loading_percentageWrapper {
    margin-top: 20px;
    height: 5px;
    width: 130px;
    background-color: #d2d2d2;
    border-radius: 15px;
}
#loading_percentageBar {
    background: linear-gradient( 90deg, rgb(1 18 49) 0%, rgb(28 74 113) 35%, rgb(28 131 222) 100%);
    width: 5%;
    height: 5px;
    border-radius: 15px;
}