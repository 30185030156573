#headerContainer {
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#headerLogo a {
    color: black;
    text-decoration: none;
    font-weight: 700;
    font-size: 28px;
}

#headerNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 520px;
    height: 21px;
    margin-left: auto;
    color: #6B6A6A;
    text-decoration: none;
}

#headerNav a {
    text-decoration: none;
    color: #6B6A6A;
    font-weight: 700;
    font-family: 'Noto Serif', serif;
}

#headerNav a:hover {
    color: black;
}

#headerBottomLine {
    position: relative;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
    border-bottom: 1px solid #C5BCD6;
}
#headerMobileIcon {
    display: none;
}

.header_mobileMenu {
    cursor: pointer;
}
@media only screen and (max-width: 700px) {
    #headerMobileIcon {
        display: flex;
    }
    #headerNav {
        display: none;
    }

}