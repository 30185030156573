#Contact_SucceedWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin: auto;
}
#Contact_Succeed_title {
    text-align: center;
    margin: 20px 0 40px 0;
    font-size: 28px;
    font-weight: 700;
    color: #47454D;

}

#Contact_Succeed_text {
    text-align: center;
    margin: 20px 0;
    font-size: 18px;
    color: #828282;
}

#Contact_Succeed_link_wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
#Contact_Succeed_link {
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    background-color: #F4F6F8;
    border-radius: 10px;
    padding: 20px;
    transition: transform .7s;
    box-shadow: 3px 3px 7px 2px rgb(0 0 0 / 5%);
    color: #2C5486;
}
#Contact_Succeed_link:hover {
    transform: translateX(-5px) translateY(-5px); /*rotateX( 25deg );*/
    box-shadow: 3px 3px 7px 2px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 700px) {
    #Contact_SucceedWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: auto;
    }
}