.App {
    width: 95%;
    max-width: 1100px;
    margin: auto;
    overflow-x: hidden;
}

html, body {
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

.bodyContainer {
    min-height: 75vh;
}